.header-n {
  .top-bar-wrapper {
    background: #474747;
    height: 25px;
    line-height: 25px; }
  .top-bar {
    max-width: 1070px;
    margin: 0 auto;
    padding: 0 10px;
    .left-menu {
      float: left; }
    .right-menu {
      float: right; } }
  .item {
    position: relative;
    color: #c2c2c2;
    font-size: 12px;
    text-decoration: none;
    transition: 0.3s;
    margin-right: 15px;
    &:hover {
      color: #fff; }
    &.stripe:after {
        position: absolute;
        top: -3px;
        right: -10px;
        width: 1px;
        height: 20px;
        background: #646464;
        content: ''; }
    &.cart {
      margin-right: 0;
      color: yellow;
      .fa {
        font-size: 13px;
        margin-top: 5px;
        margin-right: 3px;
        vertical-align: top; }
      &:hover {
        color: $darkYellow; } } } }

.header-main-section-wrapper {
  background: #323232;
  .header-main-section {
    position: relative;
    max-width: 1070px;
    margin: 0 auto;
    padding: 20px 15px 6px;
    &:after {
      display: block;
      clear: both;
      content: ''; } }
  .logo {
    loat: left; }
  .socials-n {
    float: right; }
  .socials-n {
    margin-top: 5px;
    height: 26px;
    line-height: 26px; }
  .phone-n {
    display: inline-block;
    padding-top: 1px;
    color: #c2c2c2;
    font-size: 14px;
    a {
      color: #fff; } }
  .socials-icons-n {
    display: inline-block;
    margin-left: 5px;
    a {
      &:hover {
        text-decoration: none; } }
    .fa {
      background: #fff;
      height: 26px;
      width: 26px;
      margin-left: 2px;
      margin-right: 2px;
      border-radius: 4px;
      border-bottom: solid #ddd;
      line-height: 26px;
      text-align: center;
      transition: 0.2s;
      &:hover {
        background: #ddd; } }
    .fa-facebook {
      color: #3b5998; }
    .fa-twitter {
      color: #55acee; }
    .fa-pinterest-p {
      color: #cb2027; }
    .fa-youtube-play {
      color: #bb0000; } }
  .nav-n {
    margin-top: -4px;
    float: right;
    @media(max-width: 1100px) {
      margin-top: 5px; }
    a {
      position: relative;
      font-size: 13px;
      color: #fff;
      text-transform: uppercase;
      &:hover {
        text-decoration: none; }
      &.stripe {
        margin-right: 20px;
        &:after {
          position: absolute;
          top: -3px;
          right: -10px;
          width: 1px;
          height: 20px;
          background: #646464;
          content: ''; } } } }
  .header-label {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    color: $yellow;
    font-size: 16px;
    text-shadow: 1px 1px 1px #000;
    &:before {
      position: absolute;
      top: 0;
      left: -14px;
      width: 1px;
      height: 20px;
      background: #646464;
      content: ''; } } }

