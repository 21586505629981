.products-wrapper {
    padding: 30px 10px; }
.products-container {
    max-width: 1080px;
    position: relative;
    margin: 0 auto;
    .product-page-header {
        position: relative;
        text-align: center;
        margin-bottom: 10px;
        .title {
            position: relative;
            display: inline-block;
            margin-top: 15px;
            margin-bottom: 20px;
            padding: 0 20px;
            color: $seewave;
            font-size: 39px;
            font-weight: 400;
            background-color: #fff;
            z-index: 2;
            line-height: normal;
            @media(max-width: 767px) {
                font-size: 24px; } } }
    .underline {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        max-width: 100%;
        z-index: 1; }
    .items-container {
        font-size: 0;
        max-width: 1038px;
        margin: 0 auto;
        @media(max-width: 1104px) {
            text-align: center; }
        &.bottom-items {
            text-align: center;
            .item {
                margin: 0 35px 20px;
                @media(max-width: 767px) {
                    margin: 0 5px 15px; } } }
        .item {
            display: inline-block;
            border: 1px solid #cbcdd0;
            max-width: 310px;
            width: 100%;
            margin: 0 18px 36px;
            vertical-align: top;
            @media(max-width: 767px) {
                margin: 0 5px 15px; }
            &:hover {
                .item-overlay {
                    opacity: 1; } }
            &.alternate {
                .item-title {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 76px;
                    line-height: normal;
                    h3 {
                        margin: 0;
                        font-size: 19px;
                        font-weight: 500; } }
                .short-description {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500; } } }
        .item-title {
            height: 58px;
            line-height: 58px;
            margin: 0;
            color: #fff;
            font-size: 23px;
            background-color: $seewave;
            border-bottom: 1px solid #78b2c5;
            font-weight: 500;
            text-align: center;
            @media(max-width: 767px) {
                font-size: 20px;
                height: 40px;
                line-height: 40px; } }
        .item-thumb-image {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            min-height: 328px;
            padding-bottom: 40px; }
        .item-link {
            position: absolute;
            left: calc(50% - 72px);
            bottom: 13px;
            display: block;
            width: 144px;
            height: 27px;
            line-height: 27px;
            color: #000;
            background-color: #fcc530;
            border-radius: 4px;
            font-size: 14px;
            text-decoration: none;
            text-align: center;
            font-weight: 700;
            text-shadow: 1px 1px 1px rgba(255,255,255, 1);
            transition: 0.4s;
            &:hover {
                background-color: darken(#fcc530, 15%); } }
        .item-overlay {
            opacity: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(215,215,215, 0.8);
            padding-bottom: 40px;
            transition: 0.5s;
            .overlay-link {
                position: absolute;
                left: calc(50% - 72px);
                bottom: 13px;
                display: block;
                width: 144px;
                height: 27px;
                line-height: 27px;
                color: #000;
                background-color: #fcc530;
                border-radius: 4px;
                font-size: 14px;
                text-decoration: none;
                text-align: center;
                font-weight: 700;
                text-shadow: 1px 1px 1px rgba(255,255,255, 1);
                transition: 0.4s;
                &:hover {
                    background-color: darken(#fcc530, 15%); } } }
        .item-features {
            padding: 0 15px;
            text-align: left;
            li {
                margin-bottom: 5px;
                padding-left: 30px;
                color: #000;
                font-size: 19px;
                list-style-type: none;
                background: url(../../images/tick.png) no-repeat;
                background-position: left top;
                @media(max-width: 767px) {
                    font-size: 16px; } } } } }


