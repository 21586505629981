.footerNav {
  background: #323232;
  text-align: center;
  height: 32px;
  line-height: 32px;
  ul {
    list-style-type: none;
    padding-left: 0;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    .footerNavItem {
      position: relative;
      display: inline-block;
      margin-right: 12px;
      margin-left: 12px;
      cursor: pointer;
      color: #959595;
      text-decoration: none;
      transition: 0.3s;
      &:hover {
        color: #fff; }
      &.stripe:after {
        position: absolute;
        top: 6px;
        right: -14px;
        width: 1px;
        height: 20px;
        background: #646464;
        content: ''; } } } }

.footerNavMobile {
  ul {
    list-style-type: none;
    padding-left: 0;
    text-align: center;
    width: 100%;
    display: table;
    table-layout: fixed;
    padding: 15px;
    background-color: #323232;
    .footerNavMobileItem {
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;
      font-weight: 700;
      color: #959595;
      text-decoration: none; } } }

#footerlogos {
  height: 110px;
  width: 820px;
  max-width: 100%;
  margin: 1.2em auto;
  text-align: center; }

footer {
  #footerlogos {
     .secureSIteImg {
      width: 200px;
      height: 56px; }
     .paypalImg {
        width: 92px;
        height: 56px; }
     .cardsImg {
        width: 239px;
        height: 56px; }
     .gmpImg {
        width: 71px;
        height: 56px; }
     .fdaImg {
        width: 76px;
        height: 50px; } } }

#footerwarning {
  width: 100%;
  margin: auto;
  font-size: 12px;
  padding-top: 14px;
  padding-bottom: 10px;
  line-height: 15px;
  color: #959595;
  background-color: #474747;
  text-align: center; }


#footerwarningText {
  width: 920px;
  margin: 0 auto;
  max-width: 100%; }

@media (min-width: 960px) {
  .footerNav {
    ul {
      max-width: 960px; } } }

@media (max-width: 700px) {
  #footerlogos {
    margin: 1.2em auto 5em; } }

@media (max-width: 395px) {
  #footerlogos {
    margin: 1.2em auto 12em; } }





