@media (min-width: 768px) {
  .backToTop.show {
    display: block;
    position: fixed;
    right: 4em;
    bottom: 4em;
    background: #323232;
    padding: 10px 13px;
    border-radius: 20px; }

  .backToTop .fa {
    color: #fff; } }


.wrapper {
  max-width: 1100px;
  margin: 0 auto; }
